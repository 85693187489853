.ruleGroup {
  border-width: 1px;
  flex-direction: column;
  gap: .5rem;
  padding: .5rem;
  display: flex;
}

.ruleGroup .ruleGroup-body {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.ruleGroup .ruleGroup-body:empty {
  display: none;
}

.ruleGroup .ruleGroup-header, .ruleGroup .rule {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.ruleGroup .rule .rule-value:has(.rule-value-list-item) {
  align-items: baseline;
  gap: .5rem;
  display: flex;
}

.ruleGroup .betweenRules {
  display: flex;
}

.ruleGroup .shiftActions {
  flex-direction: column;
  display: flex;
}

.ruleGroup .shiftActions > * {
  padding: 0;
}

.dndOver.rule, .dndOver.ruleGroup-header {
  padding-bottom: .5rem;
}

.dndOver.betweenRules {
  padding-top: .5rem;
}

.queryBuilder-branches .ruleGroup-body {
  margin-left: 1rem;
}

.queryBuilder-branches .rule, .queryBuilder-branches .ruleGroup .ruleGroup {
  position: relative;
}

.queryBuilder-branches .rule:before, .queryBuilder-branches .rule:after, .queryBuilder-branches .ruleGroup .ruleGroup:before, .queryBuilder-branches .ruleGroup .ruleGroup:after {
  content: "";
  border-radius: 0;
  width: .5rem;
  position: absolute;
  left: calc(-.5rem - 1px);
}

.queryBuilder-branches .rule:before, .queryBuilder-branches .ruleGroup .ruleGroup:before {
  border-width: 0 0 1px 1px;
  height: calc(50% + .5rem);
  top: -.5rem;
}

.queryBuilder-branches .rule:after, .queryBuilder-branches .ruleGroup .ruleGroup:after {
  border-width: 0 0 0 1px;
  height: 50%;
  top: 50%;
}

.queryBuilder-branches .rule:last-child:after, .queryBuilder-branches .ruleGroup .ruleGroup:last-child:after {
  display: none;
}

.queryBuilder-branches .ruleGroup .ruleGroup:before, .queryBuilder-branches .ruleGroup .ruleGroup:after {
  left: calc(-.5rem - 2px);
}

.queryBuilder-branches .ruleGroup .ruleGroup:before {
  height: calc(50% + .5rem + 1px);
  top: calc(-.5rem - 1px);
}

.queryBuilder-branches .ruleGroup .ruleGroup:after {
  height: calc(50% + 1px);
}

.queryBuilder-branches .betweenRules:before {
  content: "";
  border-width: 0 0 0 1px;
  border-radius: 0;
  width: .5rem;
  height: calc(100% + .5rem);
  position: absolute;
  top: -.5rem;
  left: calc(-.5rem - 1px);
}

.ruleGroup {
  background: #004bb733;
  border-style: solid;
  border-color: #8081a2;
  border-radius: .25rem;
}

.ruleGroup .shiftActions > * {
  cursor: pointer;
  background-color: #0000;
  border: none;
}

.dndOver.rule, .dndOver.ruleGroup-header {
  border-bottom: 2px dashed #639;
}

.dndOver.rule.dndCopy, .dndOver.ruleGroup-header.dndCopy {
  border-bottom-color: #693;
}

.dndOver.betweenRules {
  border-top: 2px dashed #639;
}

.dndOver.betweenRules.dndCopy {
  border-top-color: #693;
}

.ruleGroup.dndDragging, .rule.dndDragging {
  opacity: .5;
}

.ruleGroup .queryBuilder-dragHandle, .rule .queryBuilder-dragHandle {
  cursor: move;
}

.queryBuilder-branches .rule:before, .queryBuilder-branches .rule:after, .queryBuilder-branches .ruleGroup .ruleGroup:before, .queryBuilder-branches .ruleGroup .ruleGroup:after {
  border-style: solid;
  border-color: #8081a2;
}

.queryBuilder-branches .rule:last-child:before, .queryBuilder-branches .ruleGroup .ruleGroup:last-child:before {
  border-bottom-left-radius: .25rem;
}

.queryBuilder-branches .betweenRules {
  position: relative;
}

.queryBuilder-branches .betweenRules:before {
  border-style: solid;
  border-color: #8081a2;
}
/*# sourceMappingURL=index.077a120b.css.map */
